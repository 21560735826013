import React from 'react';
import Header from './header';
import Footer from './footer';

function Layout({children}) {
  return (
    <>
      <Header/>
      {children}
      <Footer/>
    </>
  )
}

export default Layout;

import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from './accountContext';
import { GetRequest } from './requests';

const Main = () => {
    const [productsData, setProductsData] = useState([]);
    const { setPData } = useContext(AccountContext);
    const getProductsData = async () => {
        try {
            const response = await GetRequest(`${process.env.REACT_APP_URL}/blogs`);
            setProductsData(response?.data);
            localStorage.setItem("products", JSON.stringify(response?.data))

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        getProductsData();
        const pdata = JSON.parse(localStorage.getItem("products"));
        setPData(pdata);
        getProductsData();
    }, [setPData]);
    console.log("product<<", productsData);
    return (
        <>
        </>
    );
};

export default Main;

import React from 'react';
import Modal from 'react-modal';
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

function ImageModal({ modalIsOpen = "", setIsOpen = "", src = "", alt = "", handlePrev = () => { }, handleNext = () => { } }) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            width: '50%',
            right: '50%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: '0',
            background: 'transparent',
        },
    };
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='flex justify-center items-center'>
                    <span onClick={handlePrev}><GrPrevious className='inline-block text-[48px] p-1 m-2 bg-[#fff] rounded-full text-[#334023] cursor-pointer' /></span>
                    <img className='rounded-[4px] w-[500px]' src={src} alt={alt} />
                    <span onClick={handleNext}><GrNext className='inline-block text-[48px] p-1 m-2 bg-[#fff] rounded-full text-[#334023] cursor-pointer' /></span>
                </div>
            </Modal>
        </div>
    )
}

export default ImageModal

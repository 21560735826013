import Icon from "../../assets/images/about-icon.png";
import Icon2 from "../../assets/images/eye.png";
import Icon3 from "../../assets/images/target.png";
import AboutImg from "../../assets/images/about_1-1.webp";
import AboutImg1 from "../../assets/images/about_1-2.webp";
import Client1 from "../../assets/images/client.png";
import Client2 from "../../assets/images/client2.png";
import Client3 from "../../assets/images/Client.jpeg";
import Bgicon from "../../assets/images/bgicon.png";
import Bgicon2 from "../../assets/images/bgicon2.png";
import Founder from "../../assets/images/founder.png";
import Trainer1 from '../../assets/images/trainer1.png';
import Trainer2 from '../../assets/images/trainer2.png';
import Trainer3 from '../../assets/images/trainer3.png';
import Trainer4 from '../../assets/images/trainer4.png';
import RuleImg from '../../assets/images/shose.png';
import RuleImg2 from '../../assets/images/dumble.png';
import RuleImg3 from '../../assets/images/carry.png';
import RuleImg4 from '../../assets/images/report.png';
import RuleImg5 from '../../assets/images/smoking.png';
import RuleImg6 from '../../assets/images/smoking.png';
import MembershipRule1 from '../../assets/images/refundable.png';
import MembershipRule2 from '../../assets/images/extendable.png';
import MembershipRule3 from '../../assets/images/attendance.png';
import MembershipRule4 from '../../assets/images/injury-icon.png';
import MembershipRule5 from '../../assets/images/banned-icon.png';
import logo1 from "../../assets/images/logo1.png";
import Facebook from "../../assets/images/facebook-icon.png";
import Instagram from "../../assets/images/instagram-icon.png";
import logo2 from "../../assets/images/logo2.png";
import logo3 from "../../assets/images/logo3.png";
import logo4 from "../../assets/images/logo4.png";
import logo5 from "../../assets/images/logo5.png";
import video from '../../assets/images/gymvideo.mp4';
import video2 from "../../assets/images/video1.mp4";
import Logo from "../../assets/images/logo.png";
import Allworkout from "../../assets/images/Allworkout.png";
import Bgicon3 from "../../assets/images/bgicon3.png";
import Ceo from '../../assets/images/ceo.gif';
import dancing1 from "../../assets/gymImages/gym1.png";
import dancing2 from "../../assets/gymImages/gym2.png";
import dancing3 from "../../assets/gymImages/gym3.png";
import dancing4 from "../../assets/gymImages/gym4.png";
import bootcamp1 from "../../assets/gymImages/bootcamp1.jpeg";
import bootcamp2 from "../../assets/gymImages/bootcamp2.jpeg";
import bootcamp3 from "../../assets/gymImages/bootcamp3.jpeg";
import bootcamp4 from "../../assets/gymImages/bootcamp4.jpeg";
import loaderImg from '../../assets/images/loader.gif';
import y1 from '../../assets/images/y1.png';
import y2 from '../../assets/images/y2.png';
import y3 from '../../assets/images/y3.png';
import y4 from '../../assets/images/y4.png';
import p1 from '../../assets/images/p1.jpeg';
import p2 from '../../assets/images/p2.jpeg';
import p3 from '../../assets/images/p3.jpeg';
import p4 from '../../assets/images/p4.jpeg';
import w1 from '../../assets/images/w1.webp';
import w2 from '../../assets/images/y2.png';
import w3 from '../../assets/images/y4.png';
import w4 from '../../assets/images/y3.png';
import m1 from '../../assets/images/m1.jpg';
import m2 from '../../assets/images/m2.jpg';
import m3 from '../../assets/images/m3.jpg';
import m4 from '../../assets/images/m4.jpg';
import t1 from '../../assets/images/m1.jpg';
import t2 from '../../assets/images/t2.png';
import t3 from '../../assets/images/t3.png';
import t4 from '../../assets/images/m3.jpg';
import c1 from '../../assets/images/c1.webp';
import c2 from '../../assets/images/c2.webp';
import c3 from '../../assets/images/c3.webp';
import c4 from '../../assets/images/c4.webp';
import personaltrainer1 from '../../assets/images/coach1.png';
import personaltrainer2 from '../../assets/images/coach2.png';
import personaltrainer3 from '../../assets/images/coach3.png';
import personaltrainer4 from '../../assets/images/coach4.png';
import strength1 from '../../assets/images/strength1.png';
import strength2 from '../../assets/images/strength2.png';
import strength3 from '../../assets/images/strength3.png';
import strength4 from '../../assets/images/strength4.png';
import zumba1 from '../../assets/images/zumba1.jpeg';
import zumba2 from '../../assets/images/zumba2.jpeg';
import zumba3 from '../../assets/images/zumba3.jpeg';
import zumba4 from '../../assets/images/zumba4.jpg';
import hiit1 from '../../assets/images/hiit1.webp';
import hiit2 from '../../assets/images/hiit2.webp';
import hiit3 from '../../assets/images/hiit3.webp';
import hiit4 from '../../assets/images/hiit5.jpg';
import cardio1 from '../../assets/images/cardio1.png';
import cardio2 from '../../assets/images/cardio2.png';
import cardio3 from '../../assets/images/cardio3.png';
import cardio4 from '../../assets/images/cardio4.png';
import gymvideo2 from "../../assets/videos/gymvideo2.mp4";
import thumbnail1 from "../../assets/videos/thumbnail1.webp";
import thumbnail2 from "../../assets/videos/thumbnail2.webp";
import thumbnail3 from "../../assets/videos/thumbnail3.webp";
import thumbnail4 from "../../assets/videos/thumbnail4.webp";
import thumbnail5 from "../../assets/videos/thumbnail5.webp";
import arrow from '../../assets/images/down-arrow.png';
import offer from '../../assets/images/offer.png';
import check from '../../assets/images/check-icon.png';
import yogaBanner from '../../assets/images/banners/yoga-banner.png';
import trainingBanner from '../../assets/images/banners/training-banner.png';
import cardioBanner from '../../assets/images/banners/cardio-banner.png';
import hiitBanner from '../../assets/images/banners/hiit-banner.png';
import strengthBanner from '../../assets/images/banners/strength-banner.png';
import videoBanner from '../../assets/images/banners/video-banner.png';
import zumbaBanner from '../../assets/images/banners/zumba-banner.png';
import selfdefenceBanner from '../../assets/images/banners/martial.png';
import medicalBanner from '../../assets/images/banners/medical.png';
import bootcamBanner from '../../assets/images/banners/bootcam.png';
import serviceBanner from '../../assets/images/banners/services-banner.png';
import cr1 from "../../assets/images/cardio/cr.jpeg";
import cr2 from "../../assets/images/cardio/jjhk.jpeg";
import cr3 from "../../assets/images/cardio/ll.jpeg";
import car from "../../assets/images/cardio/car.png";
import cr4 from "../../assets/images/cardio/defence1.jpeg";
import ss from "../../assets/images/cardio/ss.jpeg";
import pt5 from "../../assets/images/pt5.jpeg";
import medical1 from "../../assets/images/injury1.png";
import medical2 from "../../assets/images/injury2.png";
import medical3 from "../../assets/images/injury3.png";
import medical4 from "../../assets/images/injury4.png";
import bootcampbanner from "../../assets/inner-images/bootcamp-services.png";
import cardiobanner from "../../assets/inner-images/cardio-services.png";
import dancebanner from "../../assets/inner-images/dance-services.png";
import defencebanner from "../../assets/inner-images/defence-services.png";
import hiitbanner from "../../assets/inner-images/hiit-services.png";
import medicalbanner from "../../assets/inner-images/medical-services.png";
import strengthbanner from "../../assets/inner-images/strength-services.png";
import trainingbanner from "../../assets/inner-images/training-services.png";
import yogabanner from "../../assets/inner-images/yoga-services.png";
const ImageIcons = {
  bootcampbanner,
  cardiobanner,
  dancebanner,
  defencebanner,
  hiitbanner,
  medicalbanner,
  strengthbanner,
  trainingbanner,
  yogabanner,
  arrow,
  check,
  offer,
  Icon,
  Icon2,
  videoBanner,
  selfdefenceBanner,
  medicalBanner,
  bootcamBanner,
  serviceBanner,
  medical1,
  medical2,
  medical3,
  medical4,
  Icon3,
  AboutImg,
  AboutImg1,
  Client1,
  Client2,
  pt5,
  Client3,
  Bgicon,
  Bgicon2,
  Trainer1,
  Trainer2,
  Trainer3,
  Trainer4,
  RuleImg,
  RuleImg2,
  RuleImg3,
  RuleImg4,
  RuleImg5,
  RuleImg6,
  MembershipRule1,
  MembershipRule2,
  MembershipRule3,
  MembershipRule4,
  MembershipRule5,
  ss,
  logo1,
  logo2,
  logo3,
  cr1,
  cr2,
  cr3,
  cr4,
  logo4,
  logo5,
  video,
  Founder,
  Logo,
  Facebook,
  Instagram,
  Allworkout,
  Bgicon3,
  Ceo,
  dancing1,
  dancing2,
  car,
  dancing3,
  dancing4,
  video2,
  loaderImg,
  gymvideo2,
  thumbnail1,
  thumbnail2,
  thumbnail3,
  thumbnail4,
  thumbnail5,
  y1,
  y2,
  y3,
  y4,
  p1,
  p2,
  p3,
  p4,
  w1,
  w2,
  w3,
  w4,
  m1,
  m2,
  m3,
  m4,
  t1,
  t2,
  t3,
  t4,
  c1,
  c2,
  c3,
  c4,
  personaltrainer1,
  personaltrainer2,
  personaltrainer3,
  personaltrainer4,
  strength1,
  strength2,
  strength3,
  strength4,
  cardio1,
  cardio2,
  cardio3,
  cardio4,
  hiit1,
  hiit2,
  hiit3,
  hiit4,
  zumba1,
  zumba2,
  zumba3,
  zumba4,
  yogaBanner,
  trainingBanner,
  cardioBanner,
  hiitBanner,
  strengthBanner,
  zumbaBanner,
  bootcamp1,
  bootcamp2,
  bootcamp3,
  bootcamp4,
};

export default ImageIcons;
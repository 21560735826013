import React from 'react'
import Header from '../layout/header';
import Banner from '../components/home/banner'
import About from '../components/home/about-us';
import Services from '../components/home/service';
import Trainer from '../components/home/trainers';
import Getstarted from '../components/home/getstarted';
import Gallery from '../components/home/gallery';
import Timetable from '../components/home/timetable';
import Testimonial from '../components/home/testimonial';
import Footer from '../layout/footer';
import Blogs from '../components/home/blogs';
import Logo from '../components/shared/logos';
function Home() {
  return (
<div>
      <Header/>
      <Banner/>
      <About/>
      <Services/>
      <Trainer/>
      <Getstarted/>
      <Gallery/>
      <Timetable/>
      <Blogs/>
      <Logo/>
      <Testimonial/>
      <Footer/>
    </div>
  )
}

export default Home
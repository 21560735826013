import React, { useContext, useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import classNames from 'classnames';
import { AccountContext } from '../utils/accountContext';
import ImageModal from '../components/modal/imageModal';
import InnerPageBanner from '../components/shared/imagePageBanner';
import galleryBanner from "../assets/images/gallery-banner.png"
function Gallery() {
    const { categoryData, galleryData } = useContext(AccountContext);
    const [categoryId, setCategoryId] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);
    const handleModalOpen = (index) => {
        setCurrentImageIndex(index);
        setIsOpen(true);
    };
    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : Math.min(data.length - 1, 5)
        );
    };
    const handleNext = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex < 5 ? prevIndex + 1 : 0
        );
    };
    const handleClick = (id) => {
        setCategoryId(id);
    }
    useEffect(() => {
        const boxingCategory = categoryData.find((item) => item.categoryTitle.toLowerCase() === "hiit");
        setCategoryId(boxingCategory?._id);
    }, [categoryData])

    const data = categoryId ? galleryData.filter((item) => item?.categoryId === categoryId) : galleryData;
    return (
        <div>
            <Header />
            <InnerPageBanner img={galleryBanner} heading="Our Images" desc="Gyms play a vital role in promoting an active and healthy lifestyle." />
            <section>
                <div className="container mx-auto p-4">
                    <div className="mb-4">
                        <div className="responsivetab flex space-x-4">
                            {categoryData?.length > 0 && categoryData?.map((item, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => { handleClick(item?._id) }}
                                        className={classNames(
                                            'py-3 px-5 font-semibold text-[19px] rounded-[4px] capitalize',
                                            {
                                                'bg-[#334023] text-white': categoryId === item?._id,
                                                'bg-[#c19b57] text-[#fff]': categoryId !== item?._id,
                                            }
                                        )}
                                    >
                                        {item?.categoryTitle}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {data.length > 0 && data?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="img-container relative overflow-hidden rounded-[4px]"
                                    onClick={() => { handleModalOpen(index) }}
                                >
                                    <img
                                        src={item?.image}
                                        alt={item?.alt}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <ImageModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                src={data[currentImageIndex]?.image}
                alt={data[currentImageIndex]?.altTag}
                handlePrev={handlePrev}
                handleNext={handleNext}
            />
            <Footer />
        </div>
    );
}

export default Gallery;

import React,{useState, useEffect} from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { GetRequest } from '../utils/requests';
import {useParams } from 'react-router-dom';

function TimeTableDetail() {
  const [classDetails, setClassDetails] = useState([]);
  const {handle} = useParams();
  const getClassDetails = async () => {
    try {
      const response = await GetRequest(`${process.env.REACT_APP_URL}/timetable/data?handle=${handle}`);
      setClassDetails(response?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getClassDetails();
    // eslint-disable-next-line
  }, []);
  console.log("handle<<",handle);
  console.log("classdetails<<",classDetails);
  return (
    <>
      <Header/>
     Class Details
      <Footer/>
    </>
  )
}

export default TimeTableDetail

import React, {useContext} from 'react'
import Header from '../layout/header'
import Footer from '../layout/footer'
import Blogs from '../components/home/blogs';
import InnerPageBanner from '../components/shared/imagePageBanner';
import blogBanner from "../assets/images/blog-banner.png"
import Main from '../utils/main';
import { AccountContext } from '../utils/accountContext';
function Blog() {
    const { pData } = useContext(AccountContext);
    console.log("pdata<<",pData);
    return (
        <div>
            <Header/>
            <InnerPageBanner img={blogBanner} heading="Blogs" desc="Gyms play a vital role in promoting an active and healthy lifestyle." />
            <Main/>
            <Blogs/>
            <Footer />
        </div>
    )
}

export default Blog
import React, { useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import ImageIcons from "../components/imageComponent/ImageIcons";
import ReactPlayer from 'react-player'
import { FaPlay } from "react-icons/fa";
import InnerPageBanner from "../components/shared/imagePageBanner";
import videoBanner from "../assets/images/banners/video-banner.png";
function Videos() {
    const [playingIndex, setPlayingIndex] = useState(null);
    const iframeStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    };
    const containerStyle = {
        position: "relative",
        paddingBottom: "56.25%",
        paddingTop: "30px",
        height: 0,
        overflow: "hidden",
        width: "100%",
    };
    const handlePlay = (index) => {
        setPlayingIndex(index);
    }
    return (
        <div>
            <Header />
            <InnerPageBanner img={videoBanner} heading="Videos" desc="Gyms play a vital role in promoting an active and healthy lifestyle." />
            <section>
                <div className="container mx-auto p-4">
                    <div className="mb-4">
                        <div className="flex space-x-4">
                            <button
                                className='py-3 px-5 font-semibold text-[19px] rounded-[4px] bg-[#334023] text-white'
                            >
                                All
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {galleryItems?.map((item, index) => (
                            <div className="itemsstyle" key={index} style={containerStyle}>
                                <ReactPlayer
                                    url={item.item}
                                    loop={false}
                                    width="100%"
                                    height="100%"
                                    playbackRate={1}
                                    style={iframeStyle}
                                    playing={playingIndex === index}
                                    onPlay={() => handlePlay(index)}
                                    controls={true}
                                    light={item.image}
                                    playIcon={<FaPlay onClick={() => handlePlay(index)} className="youtube-icon h-10 w-10 text-[#fff]" />}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
const galleryItems = [
    {
        item: ImageIcons.video,
        image: ImageIcons.thumbnail1
    },
    {
        item: ImageIcons.video2,
        image: ImageIcons.thumbnail2
    },
    // {
    //     item: ImageIcons.gymvideo1,
    //     image: ImageIcons.thumbnail3
    // },
    {
        item: ImageIcons.gymvideo2,
        image: ImageIcons.thumbnail4
    },
    // {
    //     item: ImageIcons.gymvideo3,
    //     image: ImageIcons.thumbnail5
    // },
];

export default Videos;

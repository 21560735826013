import React from 'react'
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { AccountProvider } from './utils/accountContext';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/services';
import Contact from './pages/contact-us'
import Gallery from './pages/gallery';
import Videos from './pages/videos';
import Membership from './pages/membership';
import Blog from './pages/blogs';
import Faq from './pages/faq';
import PrivacyPolicy from './pages/privacy-policy';
import Terms from './pages/terms-and-conditions';
import BlogsDetail from './pages/blogsDetail';
import ServiceDetail from './pages/serviceDetail';
import TimeTableDetail from './pages/timeTableDetail';
import Thanks from './pages/thanks';
function App() {
  return (
    <>
    <AccountProvider>
    <BrowserRouter>
      <Routes>
        <Route path ="/" element={<Home />} />
        <Route path ="/about-us" element={<About />} />
        <Route path ="/services" element={<Services />} />
        <Route path ="/gallery" element={<Gallery />} />
        <Route path ="/video" element={<Videos />} />
        <Route path ="/membership" element={<Membership />} />
        <Route path ="/blogs" element={<Blog />} />
        <Route path ="/faq" element={<Faq />} />
        <Route path ="/terms-and-conditions" element={<Terms />} />
        <Route path ="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path ="/contact-us" element={<Contact />} />
        <Route path ="/blogs/:handle" element={<BlogsDetail />} />
        <Route path ="/services/:handle" element={<ServiceDetail />} />
        <Route path ="/contact-us" element={<Contact />} />
        <Route path ="/timetable/:handle" element={<TimeTableDetail />} />
        <Route path ="/thanks" element={<Thanks />} />
      </Routes>
    </BrowserRouter>
    </AccountProvider>
    </>
  )
}

export default App
